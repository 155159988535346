/**
 * User
 */
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const PHONE_EXISTS = "PHONE_EXISTS";
export const USER_CREATED = "USER_CREATED";
export const EDITAR_USUARIO = "EDITAR_USUARIO";
export const GUARDAR_USUARIO = "GUARDAR_USUARIO";
export const CUENTA_RECIBIDA = "CUENTA_RECIBIDA";
export const SET_PROPIEDAD_USER = "SET_PROPIEDAD_USER";
export const SET_PROPIEDAD_LOGIN = "SET_PROPIEDAD_LOGIN";
export const RESET_AUTH_PROVIDER = "RESET_AUTH_PROVIDER";
export const SET_CONFIRMATION_RESULT = "SET_CONFIRMATION_RESULT";
/**
 * Modal
 */
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SET_RESPONSE = "SET_RESPONSE";
export const CLEAR = "CLEAR";
export const CLEAR_MODAL = "CLEAR_MODAL";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const MODAL_COMPONENT = "MODAL_COMPONENT";
export const SHOW_ALERT = "SHOW_ALERT";
export const SHOW_SUCCESS = "SHOW_SUCCESS";
/**
 * Spinners
 */
export const HIDE_SPINNER = "HIDE_SPINNER";
export const SHOW_SPINNER = "SHOW_SPINNER";
/**
 * Pruebas
 */
export const ADD_TEST = "ADD_TEST";
export const SET_FILA = "SET_FILA";
export const SET_CONFIG = "SET_CONFIG";
export const TEST_READY = "TEST_READY";
export const POP_ESTIMULO = "POP_ESTIMULO";
export const PRUEBA_RECIBIDA = "PRUEBA_RECIBIDA";
export const RESET_MOVIMIENTO = "RESET_MOVIMIENTO";
export const NECHAPI_RECIBIDO = "NECHAPI_RECIBIDO";
export const RESET_ALL_ESTIMULOS = "RESET_ALL_ESTIMULOS";
export const SET_PROPIEDAD_ESTIMULO = "SET_PROPIEDAD_ESTIMULO";
/**
 * Resultados
 */
export const FIABILITY_RECIBIDA = "FIABILITY_RECIBIDA";
export const RESULTADOS_RECIBIDOS = "RESULTADOS_RECIBIDOS";
export const SINGLE_RESULTADO_RECIBIDO = "SINGLE_RESULTADO_RECIBIDO";
/**
 * Usuarios
 */
export const USUARIOS_RECIBIDOS = "USUARIOS_RECIBIDOS";
export const SINGLE_USER_RECIBIDO = "SINGLE_USER_RECIBIDO";
export const CREATE_USUARIO = "CREATE_USUARIO";
/**
 * Pacientes
 */
export const PACIENTES_RECIBIDOS = "PACIENTES_RECIBIDOS";
export const CREATE_PACIENTE = "CREATE_PACIENTE";
export const SET_PROPIEDAD_PACIENTE = "SET_PROPIEDAD_PACIENTE";
export const SET_DAMAGE_LOCATION = "SET_DAMAGE_LOCATION";
export const CREATE_DAMAGE = "CREATE_DAMAGE";
export const DELETE_DAMAGE = "DELETE_DAMAGE";
/**
 * Surveys
 */
export const SURVEYS_RECIBIDAS = "SURVEYS_RECIBIDAS";
export const SURVEY_TYPES_RECIBIDAS = "SURVEY_TYPES_RECIBIDAS";
export const SINGLE_SURVEY_RECIBIDA = "SINGLE_SURVEY_RECIBIDA";
/**
 * Test Types
 */
export const TEST_TYPES_RECIBIDOS = "TEST_TYPES_RECIBIDOS";
export const SINGLE_TEST_TYPE = "SINGLE_TEST_TYPE";
/**
 * Payments
 */
export const PAYMENTS_RECIBIDOS = "PAYMENTS_RECIBIDOS";
/**
 * MultiTest
 */
export const MULTITEST_RECIBIDOS = "MULTITEST_RECIBIDOS";
export const SET_MULTITEST = "SET_MULTITEST";
export const CREATE_MULTITEST = "CREATE_MULTITEST";
export const SET_PROPIEDAD_MULTITEST = "SET_PROPIEDAD_MULTITEST";
